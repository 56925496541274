<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="ID">
      <el-input v-model="selectModel.id" />
    </el-form-item>
    <el-form-item label="编号">
      <el-input v-model="selectModel.no" />
    </el-form-item>
    <el-form-item label="IMEI">
      <el-input v-model="selectModel.imei" />
    </el-form-item>
    <el-form-item label="类型">
      <el-select v-model="selectModel.machineTypeId" placeholder="请选择" clearable>
        <el-option v-for="item in machineTypes" :key="item.id" :value="item.id" :label="item.val" />
      </el-select>
    </el-form-item>
    <el-form-item label="学校">
      <el-select v-model="selectModel.collegeId" placeholder="请选择" clearable>
        <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
      </el-select>
    </el-form-item>
    <el-form-item label="是否投放">
      <el-select v-model="selectModel.isPut" placeholder="请选择" clearable>
        <el-option :value="1" label="是" />
        <el-option :value="0" label="否" />
      </el-select>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="selectModel.status" placeholder="请选择" clearable>
        <el-option :value="1" label="在线" />
        <el-option :value="0" label="离线" />
      </el-select>
    </el-form-item>
    <el-form-item label="是否启用">
      <el-select v-model="selectModel.isOpened" placeholder="请选择" clearable>
        <el-option :value="1" label="是" />
        <el-option :value="0" label="否" />
      </el-select>
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialogForm({created: true})">入录
        </el-button>
      </el-button-group>
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table :data="tableData" border stripe :default-sort="defaultSort" @sort-change="sort" style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="编号" prop="no" min-width="100px">
      <template #default="scope">
        <span class="main-column-no" @click="showQRCode(scope.row.no)">{{ scope.row.no }}</span>
      </template>
    </el-table-column>
    <el-table-column label="IMEI" prop="imei" min-width="160px" />
    <el-table-column label="类型" prop="type.name" min-width="160px" />
    <el-table-column label="状态" prop="status" min-width="80px">
      <template #default="scope">
        {{ scope.row.isOnline === 1 ? '在线' : '离线' }}
      </template>
    </el-table-column>
    <el-table-column label="学校" prop="position" min-width="200px">
      <template #default="scope">
        {{ scope.row.position?scope.row.position.collegeName:"未投放" }}
      </template>
    </el-table-column>
    <el-table-column label="上次运行时间" prop="lastUseTime" min-width="180px" />
    <el-table-column label="是否启用" prop="isOpened" min-width="100px" align="center">
      <template #default="scope">
        <el-switch :value="scope.row.isOpened === 1" disabled />
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="180">
      <template #default="scope">
        <el-dropdown>
          <el-button size="mini" type="primary">
            查看<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="showRealDialog(scope.row)">实时状态</el-dropdown-item>
              <el-dropdown-item @click="showPositionDialog(scope.row)">投放详细</el-dropdown-item>
              <el-dropdown-item @click="showLogDialog(scope.row)">日志</el-dropdown-item>
              <el-dropdown-item @click="showRunOrderDialog(scope.row)">机器运行订单</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown style="margin-left: 10px;">
          <el-button size="mini" type="primary">
            操作<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="showDialogForm({data: scope.row})">修改</el-dropdown-item>
              <el-dropdown-item @click="deleteMachinePosition(scope.row)">取回</el-dropdown-item>
              <el-dropdown-item @click="deleteMachine(scope.row)">删除</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <el-button @click="showDialogForm({data: scope.row, readonly: true})" type="text" size="small">查看</el-button> -->
        <!-- <el-button @click="showDialogForm({data: scope.row})" type="text" size="small">编辑</el-button> -->
        <!-- <el-button @click="deleteAciton(scope.row)" type="text" size="small">删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination layout="prev, pager, next,jumper" :total="total" @current-change="applyCurrentPage" />
  </div>

  <el-dialog custom-class="main-el-dialog" title="实时状态" v-model="realDialog.visibled" width="600px">
    <el-form :model="realDialog.data" label-width="80px">
      <el-form-item label="状态">
        <el-input v-model="realDialog.data.statusDes" readonly />
      </el-form-item>
      <el-form-item label="剩余时间">
        <el-input v-model="realDialog.data.runTime" readonly />
      </el-form-item>
      <el-form-item label="是否在线">
        <el-input v-model="realDialog.data.onlineDes" readonly />
      </el-form-item>
    </el-form>
  </el-dialog>

  <el-dialog custom-class="main-el-dialog" title="投放详情" v-model="positionDialog.visibled" width="600px">
    <el-form :model="positionDialog.data" label-width="80px">
      <el-form-item label="学校">
        <el-input v-model="positionDialog.data.collegeName" readonly />
      </el-form-item>
      <el-form-item label="楼栋">
        <el-input v-model="positionDialog.data.collegeBlockName" readonly />
      </el-form-item>
      <el-form-item label="投放类型">
        <el-input v-model="positionDialog.data.typeDes" readonly />
      </el-form-item>
      <el-form-item label="详细">
        <el-input v-model="positionDialog.data.detail" readonly />
      </el-form-item>
    </el-form>
  </el-dialog>

  <el-dialog custom-class="main-el-dialog" title="日志" v-model="logDialog.visibled" width="600px">
    <el-table :data="logDialog.data" style="width: 100%;">
      <el-table-column type="expand">
        <template #default="props">
          <el-form label-position="left" inline class="demo-table-expand" label-width="80px">
            <el-form-item label="时间">
              <span>{{ props.row.createTime }}</span>
            </el-form-item>
            <el-form-item label="机器地址">
              <span>{{ props.row.address }}</span>
            </el-form-item>
            <el-form-item label="反馈指令">
              <span>{{ props.row.commandHex }}</span>
            </el-form-item>
            <el-form-item v-if="props.row.orderId" label="下发ID">
              <span>{{ props.row.orderId }}</span>
            </el-form-item>
            <el-form-item v-if="props.row.orderCommandHex" label="下发指令">
              <span>{{ props.row.orderCommandHex }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="createTime" />
      <el-table-column label="反馈指令" prop="commandHex" />
    </el-table>
    <el-pagination style="margin-top: 10px; text-align: right;" layout="total, prev, next, jumper"
      :total="logDialog.total" @current-change="applyLogDialogPage" />
  </el-dialog>

  <el-dialog custom-class="main-el-dialog" title="机器运行订单" v-model="runOrderDialog.visibled" width="600px">
    <el-table :data="runOrderDialog.data" style="width: 100%;">
      <el-table-column label="时间" prop="createTime" min-width="120px" />
      <el-table-column label="类型" prop="type" min-width="80px" />
      <el-table-column label="指令" prop="actionDes" min-width="100px" />
      <el-table-column label="状态" prop="status" min-width="80px" />
    </el-table>
    <el-pagination style="margin-top: 10px; text-align: right;" layout="total, prev, next, jumper"
      :total="runOrderDialog.total" @current-change="applyRunOrderDialogPage" />
  </el-dialog>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item label="编号" prop="no" :rules="[
          { required: true, message: '名称不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.no" :readonly="!dialog.created" />
      </el-form-item>
      <el-form-item label="IMEI" prop="imei" :rules="[
          { required: true, message: 'IMEI不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.imei" />
      </el-form-item>
      <el-form-item label="类型" prop="machineTypeId" :rules="[{ required: true, trigger: 'blur'}]">
        <el-select v-model="dialog.form.machineTypeId" placeholder="请选择">
          <el-option v-for="item in machineTypes" :key="item.id" :value="item.id" :label="item.val" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="!dialog.created" label="是否启用" prop="isOpened">
        <el-switch v-model="dialog.form.isOpened" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog title="二维码" v-model="qrcodeDialog.visibled" width="300px" center>
    <el-row :gutter="10">
      <el-col :span="24">
        <div class="grid-content"><img class="qr-img" :src="qrcodeDialog.url"></div>
      </el-col>
      <!-- <el-col :span="12"><div class="grid-content" v-html="qrcodeDialog.svg" /></el-col> -->
    </el-row>
    <el-row>
      <el-col class="qr-no" :span="24">{{ qrcodeDialog.no }}</el-col>
    </el-row>
  </el-dialog>
</template>

<script>
  import { handleCatchStatus } from '../../api/handler';
  import QRCode from 'qrcode';

  export default {
    data() {
      return {
        selectModel: {},
        selectSort: null,
        defaultSort: { prop: 'id', order: 'descending' },
        tableData: [],
        total: 0,
        current: 1,
        dialog: {
          visibled: false,
          created: false,
          readonly: false,
          title: '',
          form: {},
          submit: () => true
        },
        createDialog: {
          visibled: false,
          form: {}
        },
        realDialog: {
          visibled: false,
          machine: {},
          data: {}
        },
        positionDialog: {
          visibled: false,
          machine: {},
          data: {}
        },
        logDialog: {
          visibled: false,
          machine: {},
          data: [],
          current: 1,
          total: 0
        },
        runOrderDialog: {
          visibled: false,
          machine: {},
          data: [],
          current: 1,
          total: 0
        },
        qrcodeDialog: {
          visibled: false,
          no: null,
          url: null,
          svg: null
        },
        machineTypes: [],
        colleges: []
      };
    },
    created() {
      this.$api.getCollegeMap().then(data => {
        this.colleges = data;
      });
      this.$api.getMachineTypeMap().then(data => {
        this.machineTypes = data;
      });
      this.applySortSelect(this.defaultSort);
      this.queryTableData();
    },
    mounted() {
    },
    methods: {
      queryTableData() {
        this.selectModel.sort = this.selectSort;
        this.selectModel.page = this.current - 1;
        this.$api.getMachinePage(this.selectModel)
          .then(data => {
            this.tableData = data.content;
            this.total = data.totalElements;
          })
          .catch(handleCatchStatus);
      },
      sort({ prop, order }) {
        this.applySortSelect({ prop, order });
        this.queryTableData();
      },
      applySortSelect({ prop, order }) {
        if (order) {
          this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
          return;
        }
        this.selectSort = null;
      },
      applyCurrentPage(current) {
        this.current = current;
        this.queryTableData();
      },
      showDialogForm({ data, created = false, readonly = false }) {
        this.dialog.readonly = readonly;
        this.dialog.created = created;
        this.dialog.visibled = true;
        if (data) {
          this.dialog.form = { ...data };
          this.dialog.form.isOpened = data.isOpened === 1;
        }

        let submit;
        if (created) {
          this.dialog.title = '入录';
          this.dialog.form = {};
          submit = () => this.$api.createMachine(this.dialog.form);
        } else {
          this.dialog.title = '修改';
          submit = () => {
            this.dialog.form.isOpened = this.dialog.form.isOpened ? 1 : 0;
            return this.$api.updateMachine(this.dialog.form);
          };
        }

        if (readonly) {
          this.dialog.title = '详情';
          submit = () => false;
        }

        this.dialog.submit = () => this.validateForm(() => submit()
          .then(() => {
            this.$message.success('操作成功');
            this.dialog.visibled = false;
            this.queryTableData();
          })
          .catch(handleCatchStatus));
      },
      validateForm(successFn) {
        this.$refs.dialogForm.validate(valid => {
          if (valid) {
            successFn();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm() {
        this.$refs.dialogForm.resetFields();
      },
      deleteMachine(data) {
        this.confirmAciton(data, '此操作将永久删除该机器, 是否继续?', this.$api.deleteMachine);
      },
      deleteMachinePosition(data) {
        this.confirmAciton(data, '此操作将移除该机器的投放信息, 是否继续?', this.$api.deleteMachinePosition);
      },
      confirmAciton(data, msg, postApi) {
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => postApi(data.id))
          .then(() => {
            this.$message.success('操作成功!');
            this.queryTableData();
          })
          .catch(() => this.$message.info('操作取消'));
      },
      showRealDialog(data) {
        this.$api.getMachineReal({ no: data.no }).then(resp => {
          this.realDialog.data = resp;
          this.realDialog.data.statusDes = this.$store.state.machineStatus[resp.status];
          this.realDialog.data.onlineDes = this.realDialog.data.online ? '在线' : '离线';
          this.realDialog.machine = data;
          this.realDialog.visibled = true;
        });
      },
      showPositionDialog(data) {
        if (!data.position) {
          this.$message.warning('未投放!');
          return;
        }
        this.positionDialog.data = data.position;
        this.positionDialog.data.typeDes = this.$store.state.positionType[data.position.type];
        this.positionDialog.visibled = true;
      },
      showLogDialog(data) {
        this.logDialog.visibled = true;
        this.logDialog.machine = data;
        this.logDialog.current = 1;
        this.queryLogs();
      },
      queryLogs() {
        this.$api.getMachineLogs({
          imei: this.logDialog.machine.imei,
          page: this.logDialog.current - 1
        }).then(resp => {
          this.logDialog.data = resp.content;
          this.logDialog.total = resp.totalElements;
        });
      },
      applyLogDialogPage(current) {
        this.logDialog.current = current;
        this.queryLogs();
      },
      showRunOrderDialog(data) {
        this.runOrderDialog.visibled = true;
        this.runOrderDialog.machine = data;
        this.runOrderDialog.current = 1;
        this.queryRunOrder();
      },
      queryRunOrder() {
        this.$api.getMachineRunOrder({
          machineId: this.runOrderDialog.machine.id,
          page: this.runOrderDialog.current - 1
        }).then(resp => {
          this.runOrderDialog.data = resp.content;
          this.runOrderDialog.total = resp.totalElements;
        });
      },
      applyRunOrderDialogPage(current) {
        this.runOrderDialog.current = current;
        this.queryRunOrder();
      },
      showQRCode(data) {
        // 判断正式还是测试环境
        let apiRoot = process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_BASE_API
          : process.env.VUE_APP_ONLINE_API
        QRCode.toString(data, { type: 'svg' }, (err, str) => {
          if (err) throw err;
          console.log(str);
          const url = new URL(`/wx/qrcode/${data}.jpeg`, apiRoot);
          this.qrcodeDialog.no = data;
          this.qrcodeDialog.url = url;
          this.qrcodeDialog.svg = str;
          this.qrcodeDialog.visibled = true;
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .qr-no {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .qr-img {
    width: 100%;
  }
</style>